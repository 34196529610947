import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic11 from '../assets/images/pic11.jpg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Introduction</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>E-Portfolio Introduction</h1>
                    </header>
                    <span className="image main"><img src={pic11} alt="A whiteboard with post it notes for planning" /></span>
                    <p>Informatics is a broad information science field that focuses on analyzing data
                    and designing information technology solutions to meet the needs of end users
                    (University of Washington, 2020). Informatics professionals can work in a wide
                    variety of industries, including careers such as health informatics, business
                    analysis, information architecture, data analysis, and user experience design
                    (San Jose State University, n.d.). The MS in Informatics degree at San Jose
                    State University is a holistic program with a foundational emphasis on user
                    centered design and includes courses focusing on big data analysis, web
                    application development, metadata and taxonomic systems creation, health
                    informatics, human computer interaction, and cyber security. Throughout the
                    duration of this program, I have had the opportunity to study a diverse range of
                    subjects, which have provided me with the knowledge and skills to create
                    data-driven solutions for end users.</p>
                    <h2>Organization</h2>
                    <p>This portfolio is a reflection of the knowledge that I’ve gained during
                    this program, and is split into six sections that illustrate my proficiency
                    over specific competencies. This portfolio is presented as follows: </p>
                    <blockquote>
                    <ul className="alt">
                      <li><u>Introduction</u>: This section provides a general
                      overview about the informatics field and a summary of the
                      process I used to create this portfolio.</li>
                      <li><u>Competencies</u>: There are six competencies that I’ve
                      completed in order to demonstrate my mastery over this
                      program’s learning outcomes. Each section focuses on a
                      different core competency and includes a presentation of
                      evidence to exemplify my capabilities.
                      </li>
                      <li><u>Conclusion and Affirmation</u>: The conclusion reflects my
                      experiences in the MS Informatics program and focuses on
                      my personal and professional growth during this time.
                      There is also an affirmation included that declares that
                      the included work is my own and adheres to SJSU’s privacy
                      considerations.
                      </li>
                    </ul>
                    </blockquote>
                    <h3>Process</h3>
                    <p>In order to demonstrate my mastery over these competencies, I gathered all of my projects, discussions, and assignments from my classes, and sorted them into groups that best represented each section. Once I organized my items, I had to narrow down my selections for each competency to two or three assignments. This was a difficult process because I had worked on many interesting and diverse projects throughout the program. Once my selections were chosen, I wrote my competency statements, and presented what I learned from each project or assignment as the competency’s evidence. After I finished writing my competency statements, I created a portfolio website using a react-based static site framework called Gatsby. This portfolio reflects everything I’ve learned in the MS in Informatics program, and I hope you enjoy reading about my journey.
                    </p>
                    <div class="box">
                      <h3>References</h3>
                      <p>
                        San Jose State University. (n.d.). Master of Science in Informatics. SJSU School of Information. https://ischool.sjsu.edu/ms-informatics
                      </p>
                      <p>
                        University of Washington. (2020). What is Informatics? Information School University of Washington. https://ischool.uw.edu/programs/informatics/what-is-informatics
                      </p>
                    </div>
                </div>
            </section>
        </div>

    </Layout>
)

export default Generic
